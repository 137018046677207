import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  let element = document.getElementById("broughtDonate");

  if (element == null) {
    return;
  }

  let token = document.querySelector("input[name=token]");
  let project_id = document.querySelector("input[name=project_id]");

  let app = new Vue({
    el: "#broughtDonate",
    data: {
      token: token.value,
      project_id: project_id.value,
      brought_out: null,
      code: null,
      isConfirmed: false,
      selectedCard: null, // Хранит выбранную карту
    },

    methods: {
      close() {
        $("body").removeClass("is-hidden");
        $("#modal-donate").removeClass("is-show");
      },

      confirm() {
        const formData = new FormData();
        formData.append("code", this.code);
        formData.append("authenticity_token", this.token);
        let formAction = "/sms_confirm_pay";

        axios
          .post(formAction, formData)
          .then((res) => {
            notyf.success("Успешно");
            this.isConfirmed = true; // Подтверждение прошло успешно
          })
          .catch(function (error) {
            console.log(error);
            notyf.error(error.response.data.error);
          });
      },

      send() {
        const balance = Number(document.getElementById("balanceSum").value);
        const result = balance - Number(this.brought_out);
        let formAction = "/brought_out";

        const formData = new FormData();
        formData.append("authenticity_token", this.token);
        formData.append("project_id", this.project_id);
        formData.append("brought_out", this.brought_out);
        formData.append("CardId", this.selectedCard); // Добавляем ID карты

        axios
          .post(formAction, formData)
          .then((res) => {
            notyf.success("Успешно");
            document.querySelector(
              ".balance-alert__amount-current"
            ).textContent = `Баланс: ${result} ₽`;
          })
          .catch(function (error) {
            console.log(error);
            notyf.error(error.response.data.error);
          });
      },
    },
  });
});
