import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  $("#publish").click(function (e){
    e.preventDefault();
    var form = $("#editProjectMain");
    var actionUrl = "/project_publish";

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        notyf.success("Успешно");
      })
      .catch((data) => {
        notyf.error("Попробуйте еще раз");
      });
  })

  $("#toModerate").click(function (e){
    e.preventDefault();
    var form = $("#formProjectDesc");
    var actionUrl = "/project_publish";

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        notyf.success("Успешно");
      })
      .catch((data) => {
        notyf.error("Попробуйте еще раз");
      });
  })


  $("#editProjectMain").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        notyf.success("Успешно сохранено");
      })
      .catch((data) => {
        console.log(data);
        notyf.error(data.responseJSON.error);
      });
  });

  $("#editProjectDesc").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        notyf.success("Успешно");
      })
      .catch((data) => {
        console.log(data);
        notyf.error(data.responseJSON.error);
      });
  });

  $("#editProjectLink").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        notyf.success("Успешно");
      })
      .catch((data) => {
        console.log(data);
        notyf.error(data.responseJSON.error);
      });
  });

  $("#removeFollower").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "DELETE",
      url: actionUrl,
    })
      .then((data) => {
        notyf.success("Успешно");
      })
      .catch((data) => {
        console.log(data);
        notyf.error(data.responseJSON.error);
      });
  });

});
